import { Component, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { WeatherControllerService } from '../inspiralia-api'

@Component({
  selector: 'app-weather-card',
  templateUrl: './weather-card.component.html',
  styleUrls: ['./weather-card.component.css']
})
export class WeatherCardComponent implements OnInit {

  @Input()
  set main_city(main_city:string){
    this._main_city = main_city
  }

  get main_city(){
    return this._main_city
  }

  private _main_city:string;
  log:any;
  weather:any;
  forecasts:any[] = [];
  weekdays=[
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  constructor(private weatherControllerService:WeatherControllerService) { }

  ngOnChanges(changes: SimpleChanges) {
    const city: SimpleChange = changes.main_city;
    if(!city.isFirstChange()){
      this.main_city = city.currentValue;
      this.selectWeather(this.log)
    }
  }

  round(a:number):number{
    return Math.round(a)
  }

  ngOnInit() {
    this.weatherControllerService.weatherControllerFind(null,'response').subscribe((res)=>{
      const data:any = res.body;
      this.selectWeather(data);
      this.log=data
    })
  }
  private selectWeather(data:any[]){
    let changed = false;
    for(let i = 0; i < data.length; i++){
      if(this.main_city == data[i].city){
        this.setWeather(data[i]);
        changed=true;
        break;
      }
    }
    if(!changed){
      this.setWeather(data[0])
    }
  }
  private setWeather(data){
    this.forecasts = [];
    this.weather = {
      weather:data.weather,
      icon:data.icon,
      temperature:data.temperature
    }
    for(let i=0;i<3;i++){
      let day = new Date(data.forecasts[i].day)
      data.forecasts[i].week_day= this.weekdays[day.getDay()]
      this.forecasts.push(data.forecasts[i])
    }
  }

}
