import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-new-event',
	templateUrl: './new-event.component.html',
	styleUrls: ['./new-event.component.css']
})
export class NewEventComponent implements OnInit {

	name = new FormControl('', [Validators.required, Validators.maxLength(250)]);
	description = new FormControl('', [Validators.maxLength(250)]);
	location = new FormControl('', [Validators.required, Validators.maxLength(250)]);
	date = new FormControl('', [Validators.required, Validators.maxLength(250)]);
	time = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern('[0-9]{2}:[0-9]{2}'), this.timeValidator]);
	timeCorrect = true;
	currentTags = [];
	allTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
	optionalTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];

	constructor(private dialogRef: MatDialogRef<NewEventComponent>) { }

	ngOnInit() {
	}

	closeDialog() {
		var postNotCancel = false;
		var event = {};
		this.dialogRef.close({ postNotCancel, event });
	}

	postNew() {
		var dateFormatted = this.date.value.toString().slice(11, 15) + "-" + this.getMonth(this.date.value.toString().slice(4, 7)) + "-" + this.date.value.toString().slice(8, 10) + "T" + this.time.value + ":32.301+00:00";

		var tags = this.currentTags;

		var event = {
			name: this.name.value,
			description: this.description.value,
			location: this.location.value,
			date: dateFormatted,
			time: this.time.value,
			tags: tags
		};

		var postNotCancel = true;

		this.dialogRef.close({ postNotCancel, event });
	}

	getMonth(month) {
		var monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		var monthsNumber = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
		var index = monthsName.indexOf(month);
		return monthsNumber[index];
	}

	timeValidator(control: AbstractControl): { [key: string]: boolean } | null {
		if (control.value !== undefined && ((parseInt(control.value.substring(0, 2), 10) > 23) || (parseInt(control.value.substring(3, 5), 10) > 59))) {
			return { 'timeCorrect': true };
		}
		return null;
	}

	addTag(optionalTag) {
		this.currentTags.push(optionalTag);
		var index = this.optionalTags.indexOf(optionalTag);
		if (index > -1) {
			this.optionalTags.splice(index, 1);
		}
	}

	removeTag(tag) {
		this.optionalTags.push(tag);
		var index = this.currentTags.indexOf(tag);
		if (index > -1) {
			this.currentTags.splice(index, 1);
		}
	}

	onContentChanged({ html }) {
		this.description.setValue(html);
	}
}
