import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-financial-data',
  templateUrl: './edit-financial-data.component.html',
  styleUrls: ['./edit-financial-data.component.css']
})
export class EditFinancialDataComponent implements OnInit {


  current_profit = new FormControl(this.data.financial_data.values[0].Current, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]);
  current_revenue = new FormControl(this.data.financial_data.values[1].Current, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]);
  pending_profit = new FormControl(this.data.financial_data.values[0].Pending, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]);
  pending_revenue = new FormControl(this.data.financial_data.values[1].Pending, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]);

  constructor(
    private dialogRef: MatDialogRef<EditFinancialDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){ }

  ngOnInit() {
  }

  closeDialog(){
    var postNotCancel = false;

    this.dialogRef.close({postNotCancel});
  }

  postValues(){

    var newValues = {
      'units':'k€',
      'keys':[
          'Profit',
          'Revenue'
      ],
      'values':[
          {
          'label': 'Current',
          'Profit': parseFloat(this.current_profit.value),
          'Revenue': parseFloat(this.current_revenue.value)
          },
          {
          'label': 'Pending',
          'Profit': parseFloat(this.pending_profit.value),
          'Revenue': parseFloat(this.pending_revenue.value)
          }
      ]
    }

    var postNotCancel = true;

    this.dialogRef.close({postNotCancel, newValues});
  }

}
