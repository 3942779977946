import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';

import { HourService } from '../hour.service';

@Component({
  selector: 'app-time-card',
  templateUrl: './time-card.component.html',
  styleUrls: ['./time-card.component.css']
})
export class TimeCardComponent implements OnInit {

  @Input()
  set main_city(main_city:string){
    this._main_city = main_city
  }

  get main_city(){
    return this._main_city
  }

  private _main_city:string;

  main_time:any;

  @Output()
  cityChange = new EventEmitter<string>();

  other_times:any[];
  log:any;
  images={
    'Madrid':'/assets/cities/madrid.svg',
    'Cambridge':'/assets/cities/cambridge.svg',
    'Vienna':'/assets/cities/vienna.svg',
    'Miami':'/assets/cities/miami.svg',
  }

  constructor(private hourService:HourService) {}
  ngOnChanges(changes: SimpleChanges) {
    const city: SimpleChange = changes.main_city;
    if(!city.isFirstChange()){
      this.main_city = city.currentValue;
      this.tickTack(this.log)
    }
  }
  ngOnInit() {
    this.hourService.getTime().subscribe((times)=> {
      this.log = times;
      console.log(times)
      this.tickTack(times)
    })
  }
  changeCity(city:string){
    this.cityChange.emit(city)
  }
  private tickTack(times){
    this.other_times=[];
    times.offsets.map((el)=>{
      console.log(times.datetime)
      console.log(el.value)
      let aux = new Date(times.datetime + el.value - 3600000); // We must add the correction of 3600000 because of the winter time
      if(el.city == this.main_city){
        this.main_time = {
          'city':el.city,
          'country':el.country,
          'short':el.short,
          'time':aux.getUTCHours()+':'+((aux.getUTCMinutes() < 10)?('0'+aux.getUTCMinutes()):aux.getUTCMinutes())+':'+((aux.getUTCSeconds() < 10)?('0'+aux.getUTCSeconds()):aux.getUTCSeconds()),
          'img':this.images[el.city]?this.images[el.city]:this.images['Madrid']
        }
      } else {
        this.other_times.push({
          'city':el.city,
          'short':el.short,
          'time':aux.getUTCHours()+':'+((aux.getUTCMinutes() < 10)?('0'+aux.getUTCMinutes()):aux.getUTCMinutes())
        })
      }
    })
  }

}
