import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-delete-publication-changes',
  templateUrl: './delete-publication-changes.component.html',
  styleUrls: ['./delete-publication-changes.component.css']
})
export class DeletePublicationChangesComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DeletePublicationChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){ }

  ngOnInit() {
  }

  closeDialog(){

    var deletePublicationChanges = false;
    this.dialogRef.close({deletePublicationChanges});
  }

  delete(){

    var deletePublicationChanges = true;
    this.dialogRef.close({deletePublicationChanges});
  }

}
