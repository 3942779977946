import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-show-publication',
  templateUrl: './show-publication.component.html',
  styleUrls: ['./show-publication.component.css']
})
export class ShowPublicationComponent implements OnInit {

    title = '';
    body = '';
    date = '';
    image = '';
    currentTags = [];
    allTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
    optionalTags = [];
  
    constructor(
      private dialogRef: MatDialogRef<ShowPublicationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
      ){ }
  
    ngOnInit() {
      this.image = this.data.newToShow.img_url;
      this.date = this.data.newToShow.updated_at;
      for (var i = 0; i < this.data.newToShow.tags.length; i++){
        this.currentTags.push(this.data.newToShow.tags[i]);
      }
      for (var i = 0; i < this.allTags.length; i++){
        if(!this.currentTags.includes(this.allTags[i])){
          this.optionalTags.push(this.allTags[i])
        }
      }
      this.title = this.data.newToShow.title;
      this.body = this.data.newToShow.body;
    }
  
    closeDialog(){
      this.dialogRef.close();
    }

}
