export * from './newsController.service';
import { NewsControllerService } from './newsController.service';
export * from './roleController.service';
import { RoleControllerService } from './roleController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './weatherController.service';
import { WeatherControllerService } from './weatherController.service';
export * from './clientsInsightsController.service';
import { ClientsInsightsControllerService } from './clientsInsightsController.service';
export * from './financialDataController.service';
import { FinancialDataControllerService } from './financialDataController.service';
export * from './inspiraliaGroupStaffController.service';
import { InspiraliaGroupStaffControllerService } from './inspiraliaGroupStaffController.service';
export * from './eventsController.service';
import { EventsControllerService } from './eventsController.service';
export * from './adminEventsController.service';
import { AdminEventsControllerService } from './adminEventsController.service';
export * from './ticketsController.service';
import { TicketsControllerService } from './ticketsController.service';
export * from './adminsController.service';
import { AdminsControllerService } from './adminsController.service';
export * from './marketingUserController.service';
import { MarketingUserControllerService } from './marketingUserController.service';
export * from './resourceController.service';
import { ResourceControllerService } from './resourceController.service';
export const APIS = [NewsControllerService, RoleControllerService, UserControllerService, WeatherControllerService, ClientsInsightsControllerService, FinancialDataControllerService, InspiraliaGroupStaffControllerService, EventsControllerService, AdminEventsControllerService, TicketsControllerService, AdminsControllerService, MarketingUserControllerService, ResourceControllerService];
