import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeletePublicationChangesComponent } from '../delete-publication-changes/delete-publication-changes.component';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
	selector: 'app-edit-publication',
	templateUrl: './edit-publication.component.html',
	styleUrls: ['./edit-publication.component.css']
})
export class EditPublicationComponent implements OnInit {

	title = new FormControl(this.data.newToEdit.title, [Validators.required, Validators.maxLength(250)]);
	body = new FormControl(this.data.newToEdit.body, [Validators.required]);
	image: any;
	imageInPreview = false;
	imageFile: File;
	imageEmpty = false;
	currentTags = [];
	allTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
	optionalTags = [];

	oldImage = '';
	oldTitle = '';
	oldBody = '';
	oldTags = [];

	publicationEmpty = {
		id: "0",
		tag: "0",
		date: "0",
		title: "0",
		body: "0",
		image: "0",
	};

	constructor(
		private dialogRef: MatDialogRef<EditPublicationComponent>,
		private imageCompress: NgxImageCompressService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialog,
	) { }

	ngOnInit() {
		this.image = this.data.newToEdit.img_url;
		for (var i = 0; i < this.data.newToEdit.tags.length; i++) {
			this.currentTags.push(this.data.newToEdit.tags[i]);
		}
		for (var i = 0; i < this.allTags.length; i++) {
			if (!this.currentTags.includes(this.allTags[i])) {
				this.optionalTags.push(this.allTags[i])
			}
		}

		this.oldImage = this.data.newToEdit.img_url;
		this.oldTitle = this.data.newToEdit.title;
		this.oldBody = this.data.newToEdit.body;
		this.oldTags = this.currentTags;
	}

	closeDialog() {

		if (this.oldImage == this.image && this.oldTitle == this.title.value && this.oldBody == this.body.value && this.oldTags == this.currentTags) {
			var postNotCancel = false;
			var publication = this.publicationEmpty;
			this.dialogRef.close({ postNotCancel, publication });
		}
		else {
			const dialogRef = this.dialog.open(DeletePublicationChangesComponent, {});
			dialogRef.afterClosed().subscribe((result) => {
				if (result.deletePublicationChanges == true) {
					this.confirmClose();
				}
			});
		}
	}

	confirmClose() {
		var postNotCancel = false;
		var publication = this.publicationEmpty;
		this.dialogRef.close({ postNotCancel, publication });
	}

	postNew() {
		var tags = this.currentTags;

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0');
		var yyyy = today.getFullYear();
		var date = yyyy + '-' + mm + '-' + dd + 'T09:31:26.411+00:00';

		var publication = {
			id: this.data.newToEdit.id,
			tags: tags,
			updated_at: date,
			title: this.title.value,
			body: this.body.value,
			img_url: this.image
		};

		if (this.imageFile != undefined) {
			publication.img_url = this.imageFile.name + this.image;
		}

		var postNotCancel = true;

		this.dialogRef.close({ postNotCancel, publication });
	}

	getFileSizeInMb(imageSize) {
		return imageSize / 1024 / 1024;
	}

	async processFile(imageInput: any) {
		this.imageEmpty = false;
		this.imageFile = imageInput.files[0];

		const fileSize = this.getFileSizeInMb(this.imageFile.size);
		const qualityThreshold = ((100 / fileSize) * 2);
		const quality = qualityThreshold > 70 ? 70 : qualityThreshold;

		const reader = new FileReader();
		reader.onload = e => {
			const imageResult = reader.result;

			this.imageCompress.compressFile(imageResult, -1, quality, quality).then(imageCompressed => {
				this.image = imageCompressed;
				// Function for checking image size in MB
				// const finalsize = this.imageCompress.byteCount(imageCompressed) / 1024 / 1024
				// console.log(finalsize);
			});
		}

		this.imageInPreview = true;
		await this.previewImage(reader);
		this.imageInPreview = false;
	}

	previewImage(fileReader) {
		return new Promise((resolve, reject) => {
			fileReader.readAsDataURL(this.imageFile);

			fileReader.onerror = (error) => {
				reject("Input: File could not be read:" + error);
			};

			fileReader.onloadend = () => {
				resolve(fileReader.result);
			};
		});
	}

	deleteImage() {
		this.imageEmpty = true;
	}

	addTag(optionalTag) {
		this.currentTags.push(optionalTag);
		var index = this.optionalTags.indexOf(optionalTag);
		if (index > -1) {
			this.optionalTags.splice(index, 1);
		}
	}

	removeTag(tag) {
		this.optionalTags.push(tag);
		var index = this.currentTags.indexOf(tag);
		if (index > -1) {
			this.currentTags.splice(index, 1);
		}
	}

}
