import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { NewsControllerService } from './api/newsController.service';
import { RoleControllerService } from './api/roleController.service';
import { UserControllerService } from './api/userController.service';
import { WeatherControllerService } from './api/weatherController.service';
import { ClientsInsightsControllerService } from './api/clientsInsightsController.service';
import { FinancialDataControllerService } from './api/financialDataController.service';
import { InspiraliaGroupStaffControllerService } from './api/inspiraliaGroupStaffController.service';
import { EventsControllerService } from './api/eventsController.service';
import { AdminEventsControllerService } from './api/adminEventsController.service';
import { TicketsControllerService } from './api/ticketsController.service';
import { AdminsControllerService } from './api/adminsController.service';
import { MarketingUserControllerService } from './api/marketingUserController.service';
import { ResourceControllerService } from './api/resourceController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    NewsControllerService,
    RoleControllerService,
    UserControllerService,
    WeatherControllerService,
    ClientsInsightsControllerService,
    FinancialDataControllerService,
    InspiraliaGroupStaffControllerService,
    EventsControllerService,
    AdminEventsControllerService,
    TicketsControllerService,
    AdminsControllerService,
    MarketingUserControllerService,
    ResourceControllerService
  ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
