import { Component, OnInit } from '@angular/core';

import { UserControllerService } from '../inspiralia-api/api/userController.service'
import { AdminsControllerService } from '../inspiralia-api/api/adminsController.service'
import { MarketingUserControllerService } from '../inspiralia-api/api/marketingUserController.service'

@Component({
	selector: 'app-main-dashboard',
	templateUrl: './main-dashboard.component.html',
	styleUrls: ['./main-dashboard.component.css']
})
export class MainDashboardComponent implements OnInit {

	main_city: string = 'Madrid';

	userType = "user";

	userEmail = "";
	adminsEmails = [];
	marketingEmails = [];

	constructor(
		private adminsControllerService: AdminsControllerService,
		private userControllerService: UserControllerService,
		private marketingUserControllerService: MarketingUserControllerService
	) { }

	ngOnInit() {
		this.adminsControllerService.adminsControllerFind().subscribe((admins) => {
			this.adminsEmails = admins;
			this.marketingUserControllerService.marketingUserControllerFind().subscribe((marketingUsers) => {
				this.marketingEmails = marketingUsers;
				this.userControllerService.userControllerFindMe().subscribe((user) => {
					this.userEmail = user.email;
					if (this.containsObject(this.userEmail, this.adminsEmails)) {
						this.userType = "admin";
					}
					if (this.containsObject(this.userEmail, this.marketingEmails)) {
						this.userType = "marketing";
					}
				})
			})
		})
	}

	changeCity(city: string) {
		this.main_city = city;
	}

	private containsObject(obj, list) {
		var i;
		for (i = 0; i < list.length; i++) {
			if (list[i].email === obj) {
				return true;
			}
		}
		return false;
	}

}
