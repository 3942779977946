import { Component, OnInit } from '@angular/core';

import { UserControllerService } from '../inspiralia-api/api/userController.service';
import { ResourceControllerService } from '../inspiralia-api/api/resourceController.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  constructor(
    private userControllerService: UserControllerService,
    private resourceControllerService: ResourceControllerService
  ) { }

  moreOpen = false;
  userDepartment = "";
  userEmail = "";
  resources = [];

  ngOnInit() {
    this.userControllerService.userControllerFindMe().subscribe((userSimple) =>{

      this.userEmail = userSimple.email;

      var filterRaw = {
        where: {
          email: this.userEmail
        },
      };
      var filter = JSON.stringify(filterRaw);

      this.userControllerService.userControllerFind(filter, 'response').subscribe((res) => {

        const data:any = res.body;
        this.userDepartment = data[0].department || "default";

        var filterRaw2 = {
          where: {
            department: this.userDepartment
          },
        };
        var filter2 = JSON.stringify(filterRaw2);

        this.resourceControllerService.resourceControllerFind(filter2, 'response').subscribe((res) => {

          const data:any = res.body;
          this.resources = data;

          const SharedServicesResource = { 
            department: "default",
            name: "Shared Services",
            icon: "/assets/link@2x.png",
            link: "/assets/documents/SharedServicesPortfolio_v1.0.pdf",
          };

          this.resources.push(SharedServicesResource);
        })
      })
    })
  }

  displayMoreOptions(){
		this.moreOpen = true;
  }

  displayLessOptions(){
		this.moreOpen = false;
	}

}
