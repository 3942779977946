import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import * as d3 from 'd3';

import {MatDialog } from '@angular/material/dialog';

import { EditClientsInsightsComponent } from '../dialogs/edit-clients-insights/edit-clients-insights.component';
import { EditFinancialDataComponent} from '../dialogs/edit-financial-data/edit-financial-data.component';
import { EditInspiraliaGroupStaffComponent } from '../dialogs/edit-inspiralia-group-staff/edit-inspiralia-group-staff.component';

import { ClientsInsightsControllerService } from '../inspiralia-api/api/clientsInsightsController.service';
import { FinancialDataControllerService } from '../inspiralia-api/api/financialDataController.service';
import { InspiraliaGroupStaffControllerService } from '../inspiralia-api/api/inspiraliaGroupStaffController.service';

@Component({
	selector: 'app-numeric-card',
	templateUrl: './numeric-card.component.html',
	styleUrls: ['./numeric-card.component.css']
})
export class NumericCardComponent implements OnInit {

	constructor(
		private dialog: MatDialog,
		private clientsInsightsControllerService: ClientsInsightsControllerService,
		private financialDataControllerService: FinancialDataControllerService,
		private inspiraliaGroupStaffControllerService: InspiraliaGroupStaffControllerService
	) { }

	@Input() name:string;
	@Input() summary:string;
	@Input() graph_type?:string;
	@Input() userType:string;

	@ViewChild('graphContainer') graphContainer: ElementRef;

	data:any;
	data_colors?:string[];

	tooltip:any = {
		left:0,
		top:0,
		display:'none'
	};
	tooltip_txt:string;
	svg:any;
	rectFirstHorizontal:any;
	rectNotFirstRenderedHorizontal = false;
	pie:any;
	color:any;
	data_ready:any;
	data_leyend:any[];

	svgStyle:any;

	editting = false;
	dialogWidth = screen.width/2.75 + 'px';
	dialogWidthInspiraliaGroupStaff = screen.width/2 + 'px';

	onMouseMove(e:MouseEvent) {
		this.tooltip.left= e.x
		this.tooltip.top = e.y -28
	}

	showTooltip(d){
		if(this.graph_type == 'doughnut'){
			this.tooltip_txt = '<div class="tooltip-title"><b>'+d.data.key+':</b></div><div class="value">'+parseFloat(d.data.value.toFixed(2))+'<span class="tooltip-unit"> '+(this.data.units || '')+'<span></div>'
		} else if (this.graph_type == 'horizontal_stacked_bars'){
			this.tooltip_txt = '<div class="tooltip-title"><b>'+d.data.label+':</b></div>'
			for(let i = 0; i < this.data.keys.length; i++){
				this.tooltip_txt += '<div class="aligned-left">'+this.data.keys[i]+': <span  class="detail-value">'+parseFloat(d.data[this.data.keys[i]].toFixed(2))+'</span><span class="tooltip-unit"> '+(this.data.units || '')+'<span></div>'
			}
			// this.tooltip_txt += '<div class="separator"></div>';
			// this.tooltip_txt += '<div  class="aligned-right value">Total: '+parseFloat(d.data.total_sum.toFixed(2))+'<span class="tooltip-unit"> '+(this.data.units || '')+'<span></div>'

		}
		this.tooltip.display ='block'
	}

	hideTooltip(){
		this.tooltip.display ='none'
	}

	ngOnInit() {
		if(!this.graph_type){
			this.graph_type = 'doughnut';
		}
		if (this.graph_type == 'doughnut'){
			this.inspiraliaGroupStaffControllerService.inspiraliaGroupStaffControllerFind(null, 'response').subscribe((res) => {
				const data:any = res.body;
				this.data = {
					leyend: data[0].leyend,
					values: data[0].values
				};
				//Here you can change the colors of the doughnut graph
				this.data_colors = [
					'#A0CE41',
					'#FFDE6E',
					'#59CB7E',
					'#FB95B9',
					'#9EBCF5',
					'#FF9E74',
					'#FF8C92',
					'#994d00',
					'#ff80ff',
					'#b3b300',
					'#6699ff',
					'#666699'
				];
				this.render();
			})
		}
		else if(this.graph_type == 'horizontal_stacked_bars'){
			this.financialDataControllerService.financialDataControllerFind(null, 'response').subscribe((res) => {
				const data:any = res.body;
				console.log(res.body)
				this.data = {
					units: data[0].units,
					keys: data[0].keys,
					values: data[0].values
				}
				//Here you can change the colors of the bars graph
				this.data_colors = [
					'#00AEAE',
					'#00DEBF'
				]
				this.render();
			})
		}
		else if(this.graph_type == 'icons'){
			this.clientsInsightsControllerService.clientsInsightsControllerFind(null, 'response').subscribe((res) => {
				const data:any = res.body;
		  		this.data = {
					clients: data[0].inspiralia_clients,
					new_clients: data[0].new_clients,
					interested_clients: data[0].interested_clients
				}
				this.render();
			})
		}
	}

	render(){
		if(this.graph_type == 'doughnut'){
			this.renderDoughnut();
		} else if(this.graph_type == 'horizontal_stacked_bars'){
			this.renderHorizontalStackedBars();
		} else if(this.graph_type == 'icons'){
			this.svg = d3.select(this.graphContainer.nativeElement)
	      .attr("width", 0)
	      .attr("height", 0)
			this.svgStyle={
				display:'none'
			}
		}
	}

	private renderDoughnut(){
		let rect = this.graphContainer.nativeElement.getBoundingClientRect();
		const diameter = Math.min(rect.width,rect.height);
		const chart_radius = diameter/2 - 20;
		this.svg = d3.select(this.graphContainer.nativeElement)
      .attr("width", diameter)
      .attr("height", diameter)
			.attr("viewBox","0 0 "+ diameter+" "+diameter)
      .append("g")
      .attr("transform", "translate(" + diameter / 2 + "," +
      diameter / 2 + ")");
			this.svgStyle = {
				minWidth: '150px',
			  minHeight: '150px',
			  maxWidth: '100%',
			  height: '100%'
			}
		this.color = d3.scaleOrdinal()
      .domain(Object.keys(this.data.values))
      .range(this.data_colors || d3.schemeDark2);
		this.pie = d3.pie()
	  .value( (d:any) => {return d.value })

	this.data_ready = this.pie(d3.entries(this.data.values))

    this.svg
      .selectAll('g')
			.data(this.data_ready)
			.enter()
      .append('path')
      .attr('d', d3.arc()
        .innerRadius(chart_radius*0.1)         // This is the size of the donut hole
        .outerRadius(chart_radius*0.2)
				.cornerRadius(1)
			)
			.on("mouseover", (d) => {
        this.showTooltip(d)
			})
      .on("mouseout", (d) => {
        this.hideTooltip()
      })
      .attr('fill',(d)=>{
		return ("url(#svgGrad"+this.name.replace(/\s/g, "")+d.data.key.replace(/\s/g, "")+") "+this.color(d.data.key))})
      .attr("stroke", "#ffffff")
      .style("stroke-width", "3px")
	  .style("opacity", 0.9)
	  .transition()
	  .duration(2000)
	  .attr("d", d3.arc()
        .innerRadius(chart_radius*0.4)         // This is the size of the donut hole
        .outerRadius(chart_radius)
				.cornerRadius(4)
			)
		  .delay(function(d,i){return(i*100)})


			const definitions = d3.select(this.graphContainer.nativeElement).append('defs')

			let data_keys = Object.keys(this.data.values);
			this.data_leyend=[];
			for(let i = 0; i<data_keys.length;i++){
				this.data_leyend.push({
					color:this.color([data_keys[i]]),
					label:(this.data.leyend && this.data.leyend[data_keys[i]]?this.data.leyend[data_keys[i]]:data_keys[i]),
					value:this.data.values[data_keys[i]]
				})
				let id = "svgGrad"+this.name.replace(/\s/g, "")+data_keys[i].replace(/\s/g, "")
				let gradient = definitions.append('radialGradient')
    			.attr("gradientUnits", "userSpaceOnUse")
    			.attr("cx", 0)
    			.attr("cy", 0)
					.attr("id", id)
				let colour = this.color([data_keys[i]])
				gradient.append("stop")
					.attr("offset", "100%")
					.attr("stop-color", colour)
					.attr("stop-opacity", 1);
			}
	}

	renderHorizontalStackedBars(){

		this.svg = 0;
		let rect = this.graphContainer.nativeElement.getBoundingClientRect();

		if(!this.rectNotFirstRenderedHorizontal){
			this.rectNotFirstRenderedHorizontal = true;
			this.rectFirstHorizontal = rect;
		}
		else {
			rect = this.rectFirstHorizontal;
		}

		this.svg = d3.select(this.graphContainer.nativeElement)
      .attr("width", rect.width-40)
      .attr("height", rect.height)
			.attr("viewBox","0 0 "+ (rect.width-40)+" "+rect.height)
    const g = this.svg.append("g")
      .attr("transform", "translate(" + 40 / 2 + "," +
      40 / 2 + ")");
			this.svgStyle = {
				minWidth: '150px',
			  minHeight: '150px',
			  maxWidth: '100%',
			  height: '100%'
			}

		var y = d3.scaleBand()			// x = d3.scaleBand()
			.domain(this.data.values.map((d) => { return d.label; }))
    	.rangeRound([0, rect.height-40])	// .rangeRound([0, width])
    	.paddingInner(0.1)
			.paddingOuter(0.2)
    	.align(0.1);


		this.color = d3.scaleOrdinal()
      .domain(this.data.keys)
    	.range(this.data_colors || d3.schemeDark2);

		for(let i = 0; i < this.data.values.length; i++){
			this.data.values[i].total_sum = 0
			for(let j = 0; j < this.data.keys.length; j++){
				this.data.values[i].total_sum += this.data.values[i][this.data.keys[j]];
			}

		}

		const xLimit:any = d3.max(this.data.values, (d: any) => { return d.total_sum; });

		var x = d3.scaleLinear()		// y = d3.scaleLinear()
    	.rangeRound([0, rect.width-80])	// .rangeRound([height, 0]);
	  	.domain([0, xLimit]).nice();	// y.domain...

	  g.append("g")
	    .selectAll("g")
	    .data(d3.stack().keys(this.data.keys)(this.data.values))
	    .enter().append("g")
	      .attr("fill", (d) => { return ("url(#svgGrad"+this.name.replace(/\s/g, "")+d.key.replace(/\s/g, "")+") "+this.color(d.key)); })
	    .selectAll("rect")
	    .data((d) => { return d; })
	    .enter().append("rect")
	      .attr("y", (d) => {return y(d.data.label); })	    //.attr("x", function(d) { return x(d.data.State); })
	      .attr("x", (d) => { return 0 })			    //.attr("y", function(d) { return y(d[1]); })
	      .attr("width", (d) => { return 0 })	//.attr("height", function(d) { return y(d[0]) - y(d[1]); })
	      .attr("height", y.bandwidth())					    //.attr("width", x.bandwidth());
				.on("mouseover", (d) => {
	        this.showTooltip(d)
				})
	      .on("mouseout", (d) => {
	        this.hideTooltip()
	      })
	  g.append("g")
	      .attr("class", "axis")
	      .attr("transform", "translate(0,0)") 						//  .attr("transform", "translate(0," + height + ")")
	      .call(d3.axisLeft(y).tickValues([]).tickSize(0));									//   .call(d3.axisBottom(x));

	  g.append("g")
	      .attr("class", "axis")
		  .attr("transform", "translate(0,"+(rect.height - 40)+")")				// New line
	      .call(d3.axisBottom(x).ticks(0))					//  .call(d3.axisLeft(y).ticks(null, "s"))
	    .append("text")
	      .attr("y", 2)												//     .attr("y", 2)
	      .attr("x", x(x.ticks().pop()) + 0.5) 						//     .attr("y", y(y.ticks().pop()) + 0.5)
	      .attr("dy", "0.32em")										//     .attr("dy", "0.32em")
	      .attr("fill", "#ccc")
	      .attr("font-weight", "bold")
	      .attr("text-anchor", "start")
		  .attr("transform", "translate("+ (-(rect.width-20)) +",-10)");   	// Newline

			const definitions = d3.select(this.graphContainer.nativeElement).append('defs');
			this.data_leyend = [];
			for(let i = 0; i<this.data.keys.length;i++){
				let value = '';
				for(let j = 0; j < this.data.values.length;j++){
					value += this.data.values[j].label+': '+this.data.values[j][this.data.keys[i]];
					if(j != this.data.values.length-1){
						value += ', '
					}
				}
				this.data_leyend.push({
					color:this.color([this.data.keys[i]]),
					label:(this.data.leyend && this.data.leyend[this.data.keys[i]]?this.data.leyend[this.data.keys[i]]:this.data.keys[i]),
					value:value
				})
				let id = "svgGrad"+this.name.replace(/\s/g, "")+this.data.keys[i].replace(/\s/g, "")
				let gradient = definitions.append('linearGradient')
					.attr("x1", "0%")
    			.attr("y1", "100%")
    			.attr("x2", "0%")
    			.attr("y2", "0%")
					.attr("id", id)
				let colour = this.color([this.data.keys[i]])
				gradient.append("stop")
					.attr("offset", "100%")
					.attr("stop-color", colour)
					.attr("stop-opacity", 1);

				this.svg.selectAll("rect")
					.transition()
					.duration(2000)
					.attr("x", function(d) {return x(d[0]); })
						.delay(function(d,i){return(250)})
					.attr("width", function(d){ return x(d[1]) -x(d[0]); })
						.delay(function(d,i){return(250)})
			}
	}

	startEdit(){
		this.editting = true;
	}

	endEdit(){
		this.editting = false;
	}

	editNumericCard(summary){
		if(summary == "Inspiralia Group Staff"){
			const dialogRef = this.dialog.open(EditInspiraliaGroupStaffComponent, {
				width: this.dialogWidthInspiraliaGroupStaff,
				data: {
					inspiralia_group_staff: this.data
				}
			});
			dialogRef.afterClosed().subscribe((result) => {
				if(result.postNotCancel == true){
					this.inspiraliaGroupStaffControllerService.inspiraliaGroupStaffControllerFind(null, 'response').subscribe((res) => {
						const data:any = res.body;
						this.inspiraliaGroupStaffControllerService.inspiraliaGroupStaffControllerUpdateById(data[0].id, result.newValues, 'response').subscribe((res) =>{ });
					});
					this.svg.selectAll("*").remove();
					this.data = result.newValues;
			  		this.render();
				}
			});
		}
		else if(summary == "Financial Data"){
			const dialogRef = this.dialog.open(EditFinancialDataComponent, {
				width: this.dialogWidth,
				data: {
					financial_data: this.data
				}
			});
			dialogRef.afterClosed().subscribe((result) => {
				if(result.postNotCancel == true){
					this.financialDataControllerService.financialDataControllerFind(null, 'response').subscribe((res) => {
						const data:any = res.body;
						this.financialDataControllerService.financialDataControllerUpdateById(data[0].id, result.newValues, 'response').subscribe((res) =>{ });
					});
					this.svg.selectAll("*").remove();
					this.data =  result.newValues;
			  		this.render();
				}
			});
		}
		else if(summary == "Clients Insights"){
			const dialogRef = this.dialog.open(EditClientsInsightsComponent, {
				width: this.dialogWidth,
				data: {
					clients_insights: this.data
				}
			});
			dialogRef.afterClosed().subscribe((result) => {
				if(result.postNotCancel == true){
					this.clientsInsightsControllerService.clientsInsightsControllerFind(null, 'response').subscribe((res) => {
						const data:any = res.body;
						this.clientsInsightsControllerService.clientsInsightsControllerUpdateById(data[0].id, result.newValues, 'response').subscribe((res) =>{ });
					});
					this.data = {
						clients: result.newValues.inspiralia_clients,
						new_clients: result.newValues.new_clients,
						interested_clients: result.newValues.interested_clients
					};
				}
			});
		}
	}
}
