import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-show-event',
	templateUrl: './show-event.component.html',
	styleUrls: ['./show-event.component.css']
})

export class ShowEventComponent implements OnInit {
	name = '';
	description = '';
	date = '';
	time = '';
	location = '';
	currentTags = [];
	allTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
	optionalTags = [];

	constructor(
		private dialogRef: MatDialogRef<ShowEventComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
		this.currentTags = this.data.event.tags;
		this.optionalTags = this.allTags.filter(tag => !this.currentTags.includes(tag));
		this.name = this.data.event.name;
		this.description = this.data.event.description;
		this.location = this.data.event.location;
		this.date = this.data.event.date;
		this.time = this.data.event.time;
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
