import { Component, OnInit, Input } from '@angular/core';
import { News } from '../models/news';

import { MatDialog } from '@angular/material/dialog';

import { ShowPublicationComponent } from '../dialogs/show-publication/show-publication.component';
import { NewPublicationComponent } from '../dialogs/new-publication/new-publication.component';
import { EditPublicationComponent } from '../dialogs/edit-publication/edit-publication.component';
import { DeletePublicationComponent } from '../dialogs/delete-publication/delete-publication.component';

import { NewsControllerService } from '../inspiralia-api/api/newsController.service'
import { UserControllerService } from '../inspiralia-api/api/userController.service'
import { AdminsControllerService } from '../inspiralia-api/api/adminsController.service'
import { MarketingUserControllerService } from '../inspiralia-api/api/marketingUserController.service'

@Component({
	selector: 'app-photo-card',
	templateUrl: './photo-card.component.html',
	styleUrls: ['./photo-card.component.css']
})
export class PhotoCardComponent implements OnInit {

	constructor(
		private adminsControllerService: AdminsControllerService,
		private marketingUserControllerService: MarketingUserControllerService,
		private userControllerService: UserControllerService,
		private newsControllerService: NewsControllerService,
		private dialog: MatDialog,
	) { }

	index = 1;
	news = [];
	currentNews = [];
	newsFromDB = [];
	edittingTarget = "";
	dialogWidth = screen.width / 1.8 + 'px'; //Relation obtained from the Zeplin schema
	dialogHeight = screen.height / 1.3 + 'px'; //Relation obtained from the Zeplin schema
	currentTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
	tagsSelected = [];
	tagsHelper = [];

	@Input() userType: string;
	userEmail = "";
	adminsEmails = [];
	marketingEmails = [];

	ngOnInit() {
		this.adminsControllerService.adminsControllerFind().subscribe((admins) => {
			this.adminsEmails = admins;
			this.marketingUserControllerService.marketingUserControllerFind().subscribe((marketingUsers) => {
				this.marketingEmails = marketingUsers;
				this.userControllerService.userControllerFindMe().subscribe((user) => {
					this.userEmail = user.email;
					if (this.containsObject(this.userEmail, this.adminsEmails)) {
						this.userType = "admin";
					}
					if (this.containsObject(this.userEmail, this.marketingEmails)) {
						this.userType = "marketing";
					}
					this.newsControllerService.newsControllerFind(null, 'response').subscribe((res) => {
						const data: any = res.body;
						this.news = data;
						for (var i = 0; i < this.news.length; i++) {
							this.news[i].created_at = this.formatDate(this.news[i].created_at);
							this.news[i].updated_at = this.formatDate(this.news[i].updated_at);
						}
						this.sortByDates(this.news);
						if (this.userType == "admin" || this.userType == "marketing") {
							var objectNewPublication = {
								id: '1',
								tags: ['AddNoticeObjectTag'],
								created_at: '2500.01.01',
								updated_at: '2500.01.01',
								title: 'Añadir noticia',
								body: "",
								img_url: 'whiteBack.png',
								published: true,
							};
							this.index = 2;
							this.news.unshift(objectNewPublication);
						}
						this.newsFromDB = this.news;
						this.currentNews = [this.news[0], this.news[1], this.news[2]];
					})
				})
			})
		})
	}

	private containsObject(obj, list) {
		var i;
		for (i = 0; i < list.length; i++) {
			if (list[i].email === obj) {
				return true;
			}
		}
		return false;
	}

	private formatDate(date) {
		date = date.substring(0, 4) + '.' + date.substring(5, 7) + '.' + date.substring(8, 10);
		return date;
	}

	private sortByDates(object) {
		object = object.sort(function (a, b) {
			a = new Date(a.updated_at);
			b = new Date(b.updated_at);
			return a > b ? -1 : a < b ? 1 : 0;
		});

		return object;
	}

	increaseIndex() {
		if (this.index + 1 >= this.news.length)
			this.index = 0;
		else
			this.index++;
	}

	decreaseIndex() {
		if (this.index - 1 < 0)
			this.index = this.news.length - 1;
		else
			this.index--;
	}

	next() {
		if (this.userType == 'admin' || this.userType == "marketing") {
			let newArray: News[] = [];
			newArray.push(this.news[0]);
			for (let i = 0; i < 3; i++) {
				newArray.push(this.news[this.index]);
				this.increaseIndex();
			}
			this.decreaseIndex();
			this.decreaseIndex();
			newArray.pop();
			if (newArray[2].tags[0] == 'AddNoticeObjectTag') {
				newArray.pop();
				this.increaseIndex();
				newArray.push(this.news[this.index]);
			}
			else if (newArray[1].tags[0] == 'AddNoticeObjectTag') {
				newArray[1] = newArray[2];
				newArray.pop();
				this.increaseIndex();
				newArray.push(this.news[this.index]);
			}
			this.currentNews = newArray;
		}
		else {
			let newArray: News[] = [];
			for (let i = 0; i < 3; i++) {
				newArray.push(this.news[this.index]);
				this.increaseIndex();
			}
			this.decreaseIndex();
			this.decreaseIndex();

			this.currentNews = newArray;
		}
	}

	previous() {
		if (this.userType == 'admin' || this.userType == "marketing") {
			let newArray: News[] = [];
			for (let i = 0; i < 3; i++) {
				newArray.unshift(this.news[this.index]);
				this.decreaseIndex();
			}
			newArray.unshift(this.news[0]);
			newArray.pop();
			if (newArray[2].tags[0] == 'AddNoticeObjectTag') {
				newArray[2] = newArray[1];
				newArray[1] = this.news[this.index];
				this.decreaseIndex();
			}
			if (newArray[1].tags[0] == 'AddNoticeObjectTag') {
				newArray[1] = this.news[this.index];

			}
			this.increaseIndex();
			this.increaseIndex();
			this.currentNews = newArray;
		}
		else {
			let newArray: News[] = [];
			for (let i = 0; i < 3; i++) {
				newArray.unshift(this.news[this.index]);
				this.decreaseIndex();
			}
			this.increaseIndex();
			this.increaseIndex();

			this.currentNews = newArray;
		}
	}

	removeHTMLTags(text) {
		return text.replace(/<[^>]*>/g, ' ');
	}

	limitBodyLength(body) {
		body = this.removeHTMLTags(body);
		return body.substring(0, 110) + '...';
	}

	startEdit(id) {
		this.edittingTarget = id;
	}

	cancelEdit() {
		this.edittingTarget = "";
	}

	showPublication(newToShow) {
		const dialogRef = this.dialog.open(ShowPublicationComponent, {
			width: this.dialogWidth,
			data: {
				newToShow: newToShow
			}
		});
		dialogRef.afterClosed().subscribe((result) => { });
		this.edittingTarget = "";
	}

	newPublication() {
		const dialogRef = this.dialog.open(NewPublicationComponent, {
			width: this.dialogWidth,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result.postNotCancel == true) {
				this.newsControllerService.newsControllerCreate(result.publication).subscribe((res) => {

					this.tagsHelper = this.tagsSelected;
					for (var i = 0; i < this.tagsHelper.length; i++) {
						this.dismarkTag(this.tagsHelper[i])
					}

					result.publication = res;
					result.publication.updated_at = this.formatDate(result.publication.updated_at);
					result.publication.created_at = this.formatDate(result.publication.created_at);

					var indexOfKey = result.publication.img_url.indexOf('data:image')
					var imageBase64 = result.publication.img_url.substring(indexOfKey, result.publication.img_url.length);
					result.publication.img_url = imageBase64;

					this.news.unshift(result.publication);
					this.news[0] = this.news[1];
					this.news[1] = (result.publication);
					this.newsFromDB = this.news;
					this.currentNews = [this.news[0], this.news[1], this.news[2]];
				});
			}
		});
		this.edittingTarget = "";
	}

	editPublication(newToEdit) {
		const dialogRef = this.dialog.open(EditPublicationComponent, {
			width: this.dialogWidth,
			data: {
				newToEdit: newToEdit
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result.postNotCancel == true) {
				this.newsControllerService.newsControllerUpdateById(result.publication.id, result.publication, 'response').subscribe((res) => { });
				var index = 0;
				result.publication.updated_at = this.formatDate(result.publication.updated_at);
				for (var i = 0; i < this.news.length; i++) {
					if (result.publication.id == this.news[i].id) {
						index = i;
						i = this.news.length;
					}
				}

				var indexOfKey = result.publication.img_url.indexOf('data:image')
				var imageBase64 = result.publication.img_url.substring(indexOfKey, result.publication.img_url.length);
				if (indexOfKey != -1) {
					result.publication.img_url = imageBase64;
				}

				this.news[index] = result.publication;
				this.next();
				this.previous();
			}
		});
		this.edittingTarget = "";
	}

	deletePublication(newToDelete) {
		const dialogRef = this.dialog.open(DeletePublicationComponent, {
			width: this.dialogWidth,
			data: {
				newToDelete: newToDelete
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result.deletePublication == true) {
				this.newsControllerService.newsControllerDeleteById(newToDelete.id).subscribe((res) => { });
				var index = 0;
				for (var i = 0; i < this.news.length; i++) {
					if (newToDelete.id == this.news[i].id) {
						index = i;
						i = this.news.length;
					}
				}
				this.news.splice(index, 1);
				this.next();
				this.previous();
			}
		});
		this.edittingTarget = "";
	}

	markTag(tag) {
		if (!this.tagsSelected.includes(tag)) {
			this.tagsSelected.push(tag);
		}
		var newsSelected = [];
		var idsAdded = [];
		this.index = 1;
		if (this.userType == 'admin' || this.userType == "marketing") {
			newsSelected.push(this.newsFromDB[0]);
			this.index = 2;
		}
		for (var j = 0; j < this.tagsSelected.length; j++) {
			for (var i = 0; i < this.newsFromDB.length; i++) {
				if (this.newsFromDB[i].tags.includes(this.tagsSelected[j]) && !idsAdded.includes(this.newsFromDB[i].id)) {
					newsSelected.push(this.newsFromDB[i])
					idsAdded.push(this.newsFromDB[i].id)
				}
			}
		}
		this.news = newsSelected;
		this.sortByDates(this.news);
		this.currentNews = [this.news[0], this.news[1], this.news[2]];
	}

	dismarkTag(tag) {
		var index = this.tagsSelected.indexOf(tag);
		if (index > -1) {
			this.tagsSelected.splice(index, 1);
		}
		if (this.tagsSelected.length != 0) {
			var newsSelected = [];
			var idsAdded = [];
			this.index = 1;
			if (this.userType == 'admin' || this.userType == "marketing") {
				newsSelected.push(this.newsFromDB[0]);
				this.index = 2;
			}
			for (var j = 0; j < this.tagsSelected.length; j++) {
				for (var i = 0; i < this.newsFromDB.length; i++) {
					if (this.newsFromDB[i].tags.includes(this.tagsSelected[j]) && !idsAdded.includes(this.newsFromDB[i].id)) {
						newsSelected.push(this.newsFromDB[i])
						idsAdded.push(this.newsFromDB[i].id)
					}
				}
			}
			this.news = newsSelected;
			this.sortByDates(this.news);
			this.currentNews = [this.news[0], this.news[1], this.news[2]];
		}
		else if (this.tagsSelected.length == 0) {
			this.news = this.newsFromDB;
			this.currentNews = [this.news[0], this.news[1], this.news[2]];
		}
	}

}

