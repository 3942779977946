import { Component, OnInit, Input } from '@angular/core';
import { EventsControllerService  } from '../inspiralia-api/api/eventsController.service';
import { UserControllerService } from '../inspiralia-api/api/userController.service';

@Component({
  selector: 'app-calendar-card',
  templateUrl: './calendar-card.component.html',
  styleUrls: ['./calendar-card.component.css']
})
export class CalendarCardComponent implements OnInit {

  constructor(
    private eventsControllerService: EventsControllerService,
    private userControllerService: UserControllerService
  ) { }

  @Input() userType:string;
  @Input() userEmail:string;
  
  eventsCalendar = [];
  todayEventsCalendar = [];

  ngOnInit() {

    var today = new Date();

    this.userControllerService.userControllerFindMe().subscribe((user) =>{ 

      const requestEmail = user.email; 
      var ONE_DAY = 24 * 60 * 60 * 1000;
      
      var filterRaw = {
        where: {
          and: [
            { or: [{'attendees.emailAddress.address': requestEmail}, {'organizer.emailAddress.address': requestEmail}]},  
            { and: [{ date: {gt: Date.now() - ONE_DAY}} , { date: {lt: Date.now() + ONE_DAY}}]},   
          ]
        },
        order: [
          "date ASC"
        ]
      };
      var filter = JSON.stringify(filterRaw);
        
      this.eventsControllerService.eventsControllerFind(filter, 'response').subscribe((response)=>{
        this.eventsCalendar = response.body;
        
        for (let event of this.eventsCalendar){
          let date = new Date(new Date(event.date).getTime() + 0 * 60 * 60 * 1000);
          let dateEnd = new Date(new Date(event.dateEnd).getTime() + 0 * 60 * 60 * 1000);

          if((this.sameDay(date, today))&&(!this.containsObject(event, this.todayEventsCalendar))&&(this.checkInvalidEvents(event))){
            event.date = (date.toString().substring(16, 21));
            event.dateEnd = dateEnd.toString().substring(16, 21);
            this.todayEventsCalendar.push(event)
          }
        }
        for (let event of this.todayEventsCalendar){
          if(event.name.length > 40) {
            event.name = event.name.replace(/^(.{36}[^\s]*).*/, "$1") + ' ...'; 
          }
          if((event.organizer.emailAddress.address === requestEmail)||this.checkAttendance(event, requestEmail)){
            event.accepted = true;
          }
          else {
            event.accepted = false;
          }
        }
      })
    })
  }

  private sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }

  private containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].name === obj.name) {
            return true;
        }
    }
    return false;
  }

  private checkAttendance(event, requestEmail) {
    
    var attendees = event.attendees;
    var requesterAttendeeAccepted = false;

    for(let attendee of attendees){
      if((attendee.emailAddress.address === requestEmail)&&(attendee.status.response === 'accepted')){
        requesterAttendeeAccepted = true;
      }
    }

    return requesterAttendeeAccepted;
  }

  private checkInvalidEvents(event){
    if(event.organizer.emailAddress != null){
      return ((event.name == (event.organizer.emailAddress.name + ' ')) ? false : true);
    }
    else {
      if(event.attendees.length == 0){
          return false;
      }
      else{
        var attendees = event.attendees;
        var booleanHelper = true;

        for(let attendee of attendees){
          if(booleanHelper){
            booleanHelper = (event.name == (attendee.emailAddress.name + ' ')) ? false : true;
          }
        }
      }
    }
  }

}
