import { Component, OnInit } from '@angular/core';

import { TicketsControllerService } from '../inspiralia-api/api/ticketsController.service';
import { UserControllerService } from '../inspiralia-api/api/userController.service'


@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.css']
})
export class TicketCardComponent implements OnInit {

  constructor(
    private ticketsControllerService: TicketsControllerService,
    private userControllerService: UserControllerService
  ) { }

  tickets = [];
  userEmail = "";

  ngOnInit() {

    this.userControllerService.userControllerFindMe().subscribe((user) =>{ 
      this.userEmail = user.email; 
      var filterRaw = {
        where: {
          and: [
            //{ or: [{status: 3}, {status: 4}]},
            { requester_mail: this.userEmail}
          ]
        },
        order: [
          "last_update DESC"
        ]
      }

      var filter = JSON.stringify(filterRaw)

      this.ticketsControllerService.ticketsControllerFind(filter, 'response').subscribe((res) => {
        const data:any = res.body;
        this.tickets = (data);
        for(var i = 0; i < this.tickets.length; i++){
          this.tickets[i].last_update = this.formatDate(this.tickets[i].last_update);
          this.tickets[i].inspiralia_link = 'https://inspiralia.freshservice.com/helpdesk/tickets/' + this.tickets[i].fresh_id; 
        }
      })
    }) 
  }

  private formatDate(date){
    var inputDate = new Date(date.substring(0, 4) + '.' + date.substring(5, 7) + '.' + date.substring(8, 10));
    var todaysDate = new Date();
    var yesterdaysDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

    var finalDate = '';

    if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
      finalDate = ((date.substring(11,12) == '0') ? ('Today ' + date.substring(12,16)) : 'Today ' + date.substring(11,12) + date.substring(12,16));
    }
    else if(inputDate.setHours(0,0,0,0) == yesterdaysDate.setHours(0,0,0,0)) {
      finalDate = ((date.substring(11,12) == '0') ? ('Yesterday ' + date.substring(12,16)) : 'Yesterday ' + date.substring(11,12) + date.substring(12,16));
    }
    else {
      finalDate = ((date.substring(11,12) == '0') ? (date.substring(0, 4) + '.' + date.substring(5, 7) + '.' + date.substring(8, 10) + ' ' + date.substring(12,16)) : date.substring(0, 4) + '.' + date.substring(5, 7) + '.' + date.substring(8, 10) + ' ' + date.substring(11,12) + date.substring(12,16));
    }

    //Change so now we only show YYYY.MM.DD
    finalDate = finalDate.substring(0,10);

		return finalDate;
	}

}
