import { Component, OnInit, Input } from '@angular/core';
import { getMonthColor, getMonthShort } from '../constants/months';
import { MatDialog } from '@angular/material/dialog';

import { AdminEventsControllerService } from '../inspiralia-api/api/adminEventsController.service';
import { UserControllerService } from '../inspiralia-api/api/userController.service'
import { AdminsControllerService } from '../inspiralia-api/api/adminsController.service'
import { MarketingUserControllerService } from '../inspiralia-api/api/marketingUserController.service';

import { NewEventComponent } from '../dialogs/new-event/new-event.component';
import { EditEventComponent } from '../dialogs/edit-event/edit-event.component';
import { DeleteEventComponent } from '../dialogs/delete-event/delete-event.component';
import { ShowEventComponent } from '../dialogs/show-event/show-event.component';

@Component({
	selector: 'app-event-card',
	templateUrl: './event-card.component.html',
	styleUrls: ['./event-card.component.css']
})
export class EventCardComponent implements OnInit {

	constructor(
		private adminsControllerService: AdminsControllerService,
		private marketingUserControllerService: MarketingUserControllerService,
		private userControllerService: UserControllerService,
		private adminEventsControllerService: AdminEventsControllerService,
		private dialog: MatDialog,
	) { }

	events = [];
	eventsFromDB = [];
	dialogWidth = screen.width / 1.8 + 'px'; //Relation obtained from the Zeplin schema
	currentTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
	tagsSelected = [];
	tagsHelper = [];

	@Input() userType: string;
	userEmail = "";
	adminsEmails = [];
	marketingEmails = [];

	ngOnInit() {
		this.adminsControllerService.adminsControllerFind().subscribe((admins) => {
			this.adminsEmails = admins;
			this.marketingUserControllerService.marketingUserControllerFind().subscribe((marketingUsers) => {
				this.marketingEmails = marketingUsers;
				this.userControllerService.userControllerFindMe().subscribe((user) => {
					this.userEmail = user.email;
					if (this.containsObject(this.userEmail, this.adminsEmails)) {
						this.userType = "admin";
					}
					if (this.containsObject(this.userEmail, this.marketingEmails)) {
						this.userType = "marketing";
					}

					var ONE_DAY = 24 * 60 * 60 * 1000;
					var filterRaw = {
						where: {
							date: { gt: Date.now() - ONE_DAY }
						},
						order: [
							"date DESC"
						]
					}
					var filter = JSON.stringify(filterRaw)

					this.adminEventsControllerService.adminEventsControllerFind(filter, 'response').subscribe((res) => {
						const data: any = res.body;
						this.events = data;
						for (var i = 0; i < this.events.length; i++) {
							this.events[i].day = this.getDay(this.events[i].date);
							this.events[i].date = this.formatDate(this.events[i].date) + " " + this.events[i].time;
						}
						if (this.userType == "admin" || this.userType == "marketing") {
							var eventToAdd = {
								id: 'EventToAdd',
								name: '',
								date: '0001.01.01',
								day: '',
								time: '',
								location: '',
								tags: ['AddEventObjectTag']
							};
							this.events.unshift(eventToAdd);
						}
						this.sortByDates(this.events);
						this.eventsFromDB = this.events;
					})
				})
			})
		})
	}

	private containsObject(obj, list) {
		var i;
		for (i = 0; i < list.length; i++) {
			if (list[i].email === obj) {
				return true;
			}
		}
		return false;
	}

	private formatDate(date) {
		date = date.substring(0, 4) + '.' + date.substring(5, 7) + '.' + date.substring(8, 10);
		return date;
	}

	private sortByDates(object) {
		object = object.sort(function (a, b) {
			a = new Date(a.date);
			b = new Date(b.date);
			return a > b ? 1 : a < b ? -1 : 0;
		});

		return object;
	}

	private getDay(date) {
		var dateYear = parseInt(date.substring(0, 4), 10);
		var dateMonth = parseInt(date.substring(5, 7), 10) - 1;
		var dateDay = parseInt(date.substring(8, 10), 10);

		var dayCorrected = new Date(dateYear, dateMonth, dateDay).toString().substring(0, 3);
		return dayCorrected;
	}

	getEventDay(date) {
		return date.substring(8, 10);
	}

	getEventMonth(date) {
		let month = date.split('.')[1];
		month = parseInt(month);
		return this.monthShort(month);
	}

	monthColor = (date) => {
		let month = date.split('.')[1];
		month = parseInt(month);
		return getMonthColor(month);
	};

	monthShort = (month) => getMonthShort(month);

	newEvent() {
		const dialogRef = this.dialog.open(NewEventComponent, {
			width: this.dialogWidth,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result && result.postNotCancel) {
				this.adminEventsControllerService.adminEventsControllerCreate(result.event).subscribe((res) => { });
				result.event.date = this.formatDate(result.event.date);
				this.events.push(result.event);
				this.sortByDates(this.events);
				for (var i = 0; i < this.events.length; i++) {
					this.events[i].day = this.getDay(this.events[i].date);
				}
				this.eventsFromDB = this.events;
			}
		});
	}

	editEvent(event) {
		const dialogRef = this.dialog.open(EditEventComponent, {
			width: this.dialogWidth,
			data: {
				eventToEdit: event
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result && result.postNotCancel) {
				this.adminEventsControllerService.adminEventsControllerUpdateById(result.event.id, result.event, 'response').subscribe((res) => { });
				var index = this.events.indexOf(event);
				result.event.date = this.formatDate(result.event.date);
				this.events[index] = result.event;
				this.sortByDates(this.events);
				for (var i = 0; i < this.events.length; i++) {
					this.events[i].day = this.getDay(this.events[i].date);
				}
			}
		});
	}

	deleteEvent(event) {
		const dialogRef = this.dialog.open(DeleteEventComponent, {
			width: this.dialogWidth,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result.deletePublication == true) {
				this.adminEventsControllerService.adminEventsControllerDeleteById(event.id).subscribe((res) => { });
				var index = this.events.indexOf(event);
				if (index > -1) {
					this.events.splice(index, 1);
				}
			}
		});
	}

	showEvent(event) {
		const dialogRef = this.dialog.open(ShowEventComponent, {
			width: this.dialogWidth,
			data: {
				event
			}
		});

		dialogRef.afterClosed().subscribe((result) => { });
	}

	markTag(tag) {
		if (!this.tagsSelected.includes(tag)) {
			this.tagsSelected.push(tag);
		}
		var eventsSelected = [];
		var idsAdded = [];
		if (this.userType == 'admin' || this.userType == "marketing") {
			eventsSelected.push(this.eventsFromDB[0]);
		}
		for (var j = 0; j < this.tagsSelected.length; j++) {
			for (var i = 0; i < this.eventsFromDB.length; i++) {
				if (this.eventsFromDB[i].tags.includes(this.tagsSelected[j]) && !idsAdded.includes(this.eventsFromDB[i].id)) {
					eventsSelected.push(this.eventsFromDB[i])
					idsAdded.push(this.eventsFromDB[i].id)
				}
			}
		}
		this.events = eventsSelected;
		this.sortByDates(this.events);
	}

	dismarkTag(tag) {
		var index = this.tagsSelected.indexOf(tag);
		if (index > -1) {
			this.tagsSelected.splice(index, 1);
		}
		if (this.tagsSelected.length != 0) {
			var eventsSelected = [];
			var idsAdded = [];
			if (this.userType == 'admin' || this.userType == "marketing") {
				eventsSelected.push(this.eventsFromDB[0]);
			}
			for (var j = 0; j < this.tagsSelected.length; j++) {
				for (var i = 0; i < this.eventsFromDB.length; i++) {
					if (this.eventsFromDB[i].tags.includes(this.tagsSelected[j]) && !idsAdded.includes(this.eventsFromDB[i].id)) {
						eventsSelected.push(this.eventsFromDB[i])
						idsAdded.push(this.eventsFromDB[i].id)
					}
				}
			}
			this.events = eventsSelected;
			this.sortByDates(this.events);
		}
		else if (this.tagsSelected.length == 0) {
			this.events = this.eventsFromDB;
		}
	}
}
