import { Injectable } from '@angular/core';
import { UserControllerService } from '../inspiralia-api'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private userControllerService: UserControllerService) {
    this.token = localStorage.getItem('access_token') || undefined;
  }

  private token:string;

  getToken():string | undefined {
    return this.token;
  }
  setToken(token:string){
    localStorage.setItem('access_token',token);
    this.token = token;
  }
  verifyToken(callback:Function) {
    if(!this.token){
     callback(false)
    } else {
      this.userControllerService.userControllerFindMe('response')
      .subscribe((user)=>{
        if(user){
          callback(true)
        } else {
          callback(false)
        }
      },(err)=>{
        console.log(err)
        callback(false)
      })
    }
  }
  getLoginUrl(callback) {
    this.userControllerService.userControllerUserRedirect('response')
    .toPromise().then(res=>{
      callback(null,res.body.url);
    }).catch(err=>{
      console.log(err)
      callback(err,null);
    })
  }
}
