import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-edit-event',
	templateUrl: './edit-event.component.html',
	styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit {

	name = new FormControl(this.data.eventToEdit.name, [Validators.required, Validators.maxLength(250)]);
	description = new FormControl(this.data.eventToEdit.description, [Validators.maxLength(250)]);
	location = new FormControl(this.data.eventToEdit.location, [Validators.required, Validators.maxLength(250)]);
	date = new FormControl(this.getDateCorrect(this.data.eventToEdit.date), [Validators.required, Validators.maxLength(250)]);
	time = new FormControl(this.data.eventToEdit.time, [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern('[0-9]{2}:[0-9]{2}'), this.timeValidator]);
	timeCorrect = true;
	currentTags = [];
	allTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
	optionalTags = [];
	oldTags = [];

	constructor(
		private dialogRef: MatDialogRef<EditEventComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
		for (var i = 0; i < this.data.eventToEdit.tags.length; i++) {
			this.currentTags.push(this.data.eventToEdit.tags[i]);
		}
		for (var i = 0; i < this.allTags.length; i++) {
			if (!this.currentTags.includes(this.allTags[i])) {
				this.optionalTags.push(this.allTags[i])
			}
		}

		this.oldTags = this.currentTags;
	}

	closeDialog() {

		var postNotCancel = false;

		var event = {};

		this.dialogRef.close({ postNotCancel, event });
	}

	postNew() {

		var dateFormatted = this.date.value.toString().slice(11, 15) + "-" + this.getMonth(this.date.value.toString().slice(4, 7)) + "-" + this.date.value.toString().slice(8, 10) + "T" + this.time.value + ":32.301+00:00";

		var tags = this.currentTags;

		var event = {
			id: this.data.eventToEdit.id,
			name: this.name.value,
			description: this.description.value,
			location: this.location.value,
			date: dateFormatted,
			time: this.time.value,
			tags: tags
		};

		var postNotCancel = true;

		this.dialogRef.close({ postNotCancel, event });
	}

	getMonth(month) {
		var monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		var monthsNumber = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
		var index = monthsName.indexOf(month);
		return monthsNumber[index];
	}

	getDateCorrect(date) {
		var dateYear = parseInt(date.substring(0, 4), 10);
		var dateMonth = parseInt(date.substring(5, 7), 10) - 1;
		var dateDay = parseInt(date.substring(8, 10), 10);

		var dateCorrected = new Date(dateYear, dateMonth, dateDay);
		return dateCorrected;
	}

	timeValidator(control: AbstractControl): { [key: string]: boolean } | null {
		if (control.value !== undefined && ((parseInt(control.value.substring(0, 2), 10) > 23) || (parseInt(control.value.substring(3, 5), 10) > 59))) {
			return { 'timeCorrect': true };
		}
		return null;
	}

	addTag(optionalTag) {
		this.currentTags.push(optionalTag);
		var index = this.optionalTags.indexOf(optionalTag);
		if (index > -1) {
			this.optionalTags.splice(index, 1);
		}
	}

	removeTag(tag) {
		this.optionalTags.push(tag);
		var index = this.currentTags.indexOf(tag);
		if (index > -1) {
			this.currentTags.splice(index, 1);
		}
	}
}
