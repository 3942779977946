import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';

import { AppRoutingModule } from './app-routing.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { NumericCardComponent } from './numeric-card/numeric-card.component';
import { PhotoCardComponent } from './photo-card/photo-card.component';
import { TimeCardComponent } from './time-card/time-card.component';
import { WeatherCardComponent } from './weather-card/weather-card.component';
import { ChatCardComponent } from './chat-card/chat-card.component';
import { TicketCardComponent } from './ticket-card/ticket-card.component';
import { CalendarCardComponent } from './calendar-card/calendar-card.component';
import { LoginComponent } from './login/login.component';
import { EventCardComponent } from './event-card/event-card.component';
import { ShowPublicationComponent } from './dialogs/show-publication/show-publication.component';
import { NewPublicationComponent } from './dialogs/new-publication/new-publication.component';
import { EditPublicationComponent } from './dialogs/edit-publication/edit-publication.component';
import { DeletePublicationComponent } from './dialogs/delete-publication/delete-publication.component';
import { DeletePublicationChangesComponent } from './dialogs/delete-publication-changes/delete-publication-changes.component';
import { EditClientsInsightsComponent } from './dialogs/edit-clients-insights/edit-clients-insights.component';
import { EditFinancialDataComponent } from './dialogs/edit-financial-data/edit-financial-data.component';
import { EditInspiraliaGroupStaffComponent } from './dialogs/edit-inspiralia-group-staff/edit-inspiralia-group-staff.component';
import { NewEventComponent } from './dialogs/new-event/new-event.component';
import { EditEventComponent } from './dialogs/edit-event/edit-event.component';
import { DeleteEventComponent } from './dialogs/delete-event/delete-event.component';
import { ShowEventComponent } from './dialogs/show-event/show-event.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { QuillModule } from 'ngx-quill';
import { NgxImageCompressService } from 'ngx-image-compress';

import {
	ApiModule, Configuration,
	ConfigurationParameters,
	BASE_PATH
} from './inspiralia-api';

import {
	HttpClientModule,
	HTTP_INTERCEPTORS
} from '@angular/common/http'

import { AuthInterceptor } from './auth/auth.interceptor';
import { Error404Component } from './error404/error404.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';

export function apiConfigFactory(): Configuration {
	const params: ConfigurationParameters = {
		basePath: "https://inspiralia-api.pitagorinesgroup.com" //Change to: https://inspiralia-api.pitagorinesgroup.com or http://localhost:3000
	}
	return new Configuration(params);
}

const quillConfig = {
	modules: {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			['link'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }],
			[{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
			['clean'],
		]
	},
	placeholder: 'Cuerpo noticia'
}

@NgModule({
	declarations: [
		AppComponent,
		MainDashboardComponent,
		SidenavComponent,
		HeaderComponent,
		NumericCardComponent,
		PhotoCardComponent,
		TimeCardComponent,
		WeatherCardComponent,
		ChatCardComponent,
		TicketCardComponent,
		CalendarCardComponent,
		LoginComponent,
		EventCardComponent,
		Error404Component,
		ShowPublicationComponent,
		NewPublicationComponent,
		EditPublicationComponent,
		DeletePublicationComponent,
		DeletePublicationChangesComponent,
		EditClientsInsightsComponent,
		EditFinancialDataComponent,
		EditInspiraliaGroupStaffComponent,
		NewEventComponent,
		EditEventComponent,
		DeleteEventComponent,
		ShowEventComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ApiModule.forRoot(apiConfigFactory),
		HttpClientModule,
		BrowserAnimationsModule,
		MatDialogModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTooltipModule,
		FormsModule,
		ReactiveFormsModule,
		LottieAnimationViewModule.forRoot(),
		QuillModule.forRoot({ ...quillConfig })
	],
	entryComponents: [
		ShowPublicationComponent,
		NewPublicationComponent,
		EditPublicationComponent,
		DeletePublicationComponent,
		DeletePublicationChangesComponent,
		EditClientsInsightsComponent,
		EditFinancialDataComponent,
		EditInspiraliaGroupStaffComponent,
		NewEventComponent,
		EditEventComponent,
		DeleteEventComponent,
		ShowEventComponent
	],
	providers: [
		{ provide: BASE_PATH, useValue: 'https://inspiralia-api.pitagorinesgroup.com' },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		NgxImageCompressService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
