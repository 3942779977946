import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-inspiralia-group-staff',
  templateUrl: './edit-inspiralia-group-staff.component.html',
  styleUrls: ['./edit-inspiralia-group-staff.component.css']
})
export class EditInspiraliaGroupStaffComponent implements OnInit {

  EditInspiraliaGroupStaffForm = new FormGroup({});
  names = [];
  smallnames = [];
  values = [];
  numCategories = 0;
  categories = [];
  objectToHandle = {};

  constructor(
    private dialogRef: MatDialogRef<EditInspiraliaGroupStaffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){ }

  ngOnInit() {

      var count = Object.keys(this.data.inspiralia_group_staff.leyend).length;
      var inspiralia_group_staff_clean = [];
      var object = {};

      for(var i = 0; i < count; i++){
        inspiralia_group_staff_clean.push(this.data.inspiralia_group_staff.values[Object.keys(this.data.inspiralia_group_staff.leyend)[i]])
        inspiralia_group_staff_clean.push(this.data.inspiralia_group_staff.leyend[Object.keys(this.data.inspiralia_group_staff.leyend)[i]])
        inspiralia_group_staff_clean.push(Object.keys(this.data.inspiralia_group_staff.leyend)[i])
      }
      
      for(var i = 0; i < inspiralia_group_staff_clean.length/3; i++){
          var x = {
              name: inspiralia_group_staff_clean[2 + 3*i],
              smallname: inspiralia_group_staff_clean[1 + 3*i],
              value: inspiralia_group_staff_clean[0 + 3*i]
            };
        object[i] = x;
      }

      this.objectToHandle = object;
      var count_clean = Object.keys(object).length;
      this.numCategories = count_clean;

      for(var i = 0; i < count_clean; i++){
          this.EditInspiraliaGroupStaffForm.addControl(('name' + i), new FormControl(object[Object.keys(object)[i]].name, Validators.required));
          this.EditInspiraliaGroupStaffForm.addControl(('smallname' + i), new FormControl(object[Object.keys(object)[i]].smallname, Validators.required));
          this.EditInspiraliaGroupStaffForm.addControl(('value' + i), new FormControl(object[Object.keys(object)[i]].value, Validators.required));
          this.names.push('name' + i);
          this.smallnames.push('smallname' + i);
          this.values.push('value' + i);
          this.categories.push('categorie' + i);
      }
  }

  closeDialog(){

    var postNotCancel = false;

    this.dialogRef.close({postNotCancel});
  }

  postValues(){

    var newValues = {};
    var leyend = { };
    var values = { };

    var objectsArray = [];

    for (var i = 0; i < this.numCategories; i++){
      objectsArray.push({
        name: this.EditInspiraliaGroupStaffForm.controls[Object.keys(this.EditInspiraliaGroupStaffForm.controls)[0 + 3*i]].value,
        smallname: this.EditInspiraliaGroupStaffForm.controls[Object.keys(this.EditInspiraliaGroupStaffForm.controls)[1 + 3*i]].value,
        value: parseInt(this.EditInspiraliaGroupStaffForm.controls[Object.keys(this.EditInspiraliaGroupStaffForm.controls)[2 + 3*i]].value, 10)
      })
    }
    
    objectsArray.sort(function(a, b) {
      return parseFloat(a.value) - parseFloat(b.value);
    });

    for(var i = 0; i < this.numCategories; i++){
      this.objectToHandle [i] = objectsArray[this.numCategories - 1 - i];
    }

    for (var i = 0; i < this.numCategories; i++){
      leyend [ this.objectToHandle[Object.keys(this.objectToHandle)[i]].name ] = this.objectToHandle[Object.keys(this.objectToHandle)[i]].smallname;
      values [ this.objectToHandle[Object.keys(this.objectToHandle)[i]].name ] = this.objectToHandle[Object.keys(this.objectToHandle)[i]].value;
    }
    
    newValues[ 'leyend' ] = leyend;
    newValues[ 'values' ] = values;

    var postNotCancel = true;

    this.dialogRef.close({postNotCancel, newValues});
  }

  removeCategory(categorie){

    var numberCategoy = parseInt(categorie.slice(9, categorie.length), 10);

    var newObject = {};

    for (var i = 0; i < numberCategoy; i++){
      newObject[i] = this.objectToHandle[Object.keys(this.objectToHandle)[i]];
    }
    for (var i = numberCategoy; i < (this.numCategories - 1); i++){
      newObject [i] = this.objectToHandle[Object.keys(this.objectToHandle)[i+1]];
    }

    this.EditInspiraliaGroupStaffForm = new FormGroup({});
    this.names = [];
    this.smallnames = [];
    this.values = [];
    this.categories = [];
    this.objectToHandle = newObject;

    var count_clean = Object.keys(newObject).length;
    this.numCategories = count_clean;
    for(var i = 0; i < count_clean; i++){
        this.EditInspiraliaGroupStaffForm.addControl(('name' + i), new FormControl(newObject[Object.keys(newObject)[i]].name, Validators.required));
        this.EditInspiraliaGroupStaffForm.addControl(('smallname' + i), new FormControl(newObject[Object.keys(newObject)[i]].smallname, Validators.required));
        this.EditInspiraliaGroupStaffForm.addControl(('value' + i), new FormControl(newObject[Object.keys(newObject)[i]].value, Validators.required));
        this.names.push('name' + i);
        this.smallnames.push('smallname' + i);
        this.values.push('value' + i);
        this.categories.push('categorie' + i);
    }
  }

  addCategory() {

    this.names.push('name' + this.numCategories);
    this.smallnames.push('smallname' + this.numCategories);
    this.values.push('value' + this.numCategories);
    this.categories.push('categorie' + this.numCategories);

    var x = {
      name: "",
      smallname: "",
      value: 0
    };

    this.objectToHandle[this.numCategories] = x;

    this.EditInspiraliaGroupStaffForm.addControl(('name' + this.numCategories), new FormControl(null, Validators.required));
    this.EditInspiraliaGroupStaffForm.addControl(('smallname' + this.numCategories), new FormControl(null, Validators.required));
    this.EditInspiraliaGroupStaffForm.addControl(('value' + this.numCategories), new FormControl(null, Validators.required));
        
    this.numCategories++;
  }
}
