import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
	selector: 'app-new-publication',
	templateUrl: './new-publication.component.html',
	styleUrls: ['./new-publication.component.css']
})
export class NewPublicationComponent implements OnInit {

	title = new FormControl('', [Validators.required, Validators.maxLength(250)]);
	body = new FormControl('', [Validators.required]);
	image: any;
	imageInPreview = false;
	imageFile: File;
	imageEmpty = true;
	currentTags = [];
	allTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];
	optionalTags = ["IS", "USA", "FEN", "m27", "Corporate", "TVB"];

	constructor(private dialogRef: MatDialogRef<NewPublicationComponent>, private imageCompress: NgxImageCompressService) { }

	ngOnInit() {
	}

	closeDialog() {

		var postNotCancel = false;

		var publication = {
			id: "0",
			tag: "0",
			date: "0",
			title: "0",
			body: "0",
			image: "0",
		};

		this.dialogRef.close({ postNotCancel, publication });
	}

	postNew() {

		var tags = this.currentTags;

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0');
		var yyyy = today.getFullYear();
		var date = yyyy + '-' + mm + '-' + dd + 'T11:13:07.016+00:00';

		var publication = {
			tags: tags,
			title: this.title.value,
			body: this.body.value,
			img_url: this.imageFile.name + this.image,
			published: true,
			created_at: date,
			updated_at: date,
		};

		var postNotCancel = true;

		this.dialogRef.close({ postNotCancel, publication });
	}

	getFileSizeInMb(imageSize) {
		return imageSize / 1024 / 1024;
	}

	async processFile(imageInput: any) {
		this.imageEmpty = false;
		this.imageFile = imageInput.files[0];

		const fileSize = this.getFileSizeInMb(this.imageFile.size);
		const qualityThreshold = ((100 / fileSize) * 2);
		const quality = qualityThreshold > 70 ? 70 : qualityThreshold;

		const reader = new FileReader();
		reader.onload = e => {
			const imageResult = reader.result;

			this.imageCompress.compressFile(imageResult, -1, quality, quality).then(imageCompressed => {
				this.image = imageCompressed;
				// Function for checking image size in MB
				// const finalsize = this.imageCompress.byteCount(imageCompressed) / 1024 / 1024
				// console.log(finalsize);
			});
		}

		this.imageInPreview = true;
		await this.previewImage(reader);
		this.imageInPreview = false;
	}

	previewImage(fileReader) {
		return new Promise((resolve, reject) => {
			fileReader.readAsDataURL(this.imageFile);

			fileReader.onerror = (error) => {
				reject("Input: File could not be read:" + error);
			};

			fileReader.onloadend = () => {
				resolve(fileReader.result);
			};
		});
	}

	deleteImage() {
		this.imageEmpty = true;
	}

	addTag(optionalTag) {
		this.currentTags.push(optionalTag);
		var index = this.optionalTags.indexOf(optionalTag);
		if (index > -1) {
			this.optionalTags.splice(index, 1);
		}
	}

	onContentChanged({ html }) {
		this.body.setValue(html);
	}

	removeTag(tag) {
		this.optionalTags.push(tag);
		var index = this.currentTags.indexOf(tag);
		if (index > -1) {
			this.currentTags.splice(index, 1);
		}
	}

}
