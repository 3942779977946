import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-clients-insights',
  templateUrl: './edit-clients-insights.component.html',
  styleUrls: ['./edit-clients-insights.component.css']
})
export class EditClientsInsightsComponent implements OnInit {

  inspiralia_clients = new FormControl(this.data.clients_insights.clients, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]);
  new_clients = new FormControl(this.data.clients_insights.new_clients, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]);
  interested_clients = new FormControl(this.data.clients_insights.interested_clients, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]); 

  constructor(
    private dialogRef: MatDialogRef<EditClientsInsightsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ){ }

  ngOnInit() {
  }

  closeDialog(){
    var postNotCancel = false;

    this.dialogRef.close({postNotCancel});
  }

  postValues(){

    var newValues = {
		  inspiralia_clients: ((typeof this.inspiralia_clients.value === "string") ? parseInt(this.inspiralia_clients.value, 10) : this.inspiralia_clients.value),
		  new_clients: ((typeof this.new_clients.value === "string") ? parseInt(this.new_clients.value, 10) : this.new_clients.value),
		  interested_clients: ((typeof this.interested_clients.value === "string") ? parseInt(this.interested_clients.value, 10) : this.interested_clients.value)
	  }

    var postNotCancel = true;

    this.dialogRef.close({postNotCancel, newValues});
  }

}
