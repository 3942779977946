import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { LoginComponent } from './login/login.component';
import { Error404Component } from './error404/error404.component'

import { AuthGuard } from './auth/auth.guard'

const routes: Routes = [
  { path: 'login', component: LoginComponent ,pathMatch: 'full'},
  { path: '', component: MainDashboardComponent, pathMatch: 'full', canActivate:[AuthGuard]}, //AuthGuard add when creating version to show in prod
  { path: '**', component: Error404Component }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})

export class AppRoutingModule { }
