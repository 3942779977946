import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService:AuthService,
    private router: Router
  ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((res,_rej)=>{
        let token = next.queryParamMap.get('auth');
        if(token){
          this.authService.setToken(token);
          this.router.navigate(['/'])
        }
        this.authService.verifyToken((isValid)=>{
          if(!isValid){
            this.authService.getLoginUrl((err,url)=>{
              if(!err && !!url){
                window.open(url,'_self')
                res(false);
              } else {
                this.router.navigate(['/login'])
                res(false);
              }
            })
          } else {
            res(true);
          }
      })
    })
  }

}
