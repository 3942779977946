import { Component, OnInit, Input } from '@angular/core';

import { UserControllerService } from '../inspiralia-api/api/userController.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public lottieConfig: Object;

  userName = "";
  userEmail = "";

  constructor(
    private userControllerService: UserControllerService
  ) { 
    this.lottieConfig = {
      path: 'assets/lottie.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
  }

  ngOnInit() {
    this.userControllerService.userControllerFindMe().subscribe((userSimple) =>{

      this.userEmail = userSimple.email;

      var filterRaw = {
        where: {
          email: this.userEmail
        },
      };
      var filter = JSON.stringify(filterRaw);

      this.userControllerService.userControllerFind(filter, 'response').subscribe((res) => {
        const data:any = res.body;
        this.userName = data[0].given_name;
      })
    })
  }

}
