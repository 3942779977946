import { Injectable } from '@angular/core';
import { connect } from 'socket.io-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HourService {
  private socket;
  constructor() {
    this.socket = connect('https://inspiralia-api.pitagorinesgroup.com:3001/hours');
  }
  getTime():Observable<any>{
    return Observable.create((observer) => {
      this.socket.on('datetime',(response)=>{
        observer.next(response);
      })
    })
  }
}
