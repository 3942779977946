export const monthsShort: string[] = [
    'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
]

export function getMonthShort(month) {
    return monthsShort[month - 1];
}

export function getMonthColor(month) {
    if (month % 3 == 0) return 'blue';
    else if ((month + 1) % 3 == 0) return 'pink';
    else if ((month + 2) % 3 == 0) return 'purple';
}